<template>
  <div class="last-v-dos">
    <div class="titulo-u-noticias">
      <h4>ULTIMAS <span>GALERIAS</span></h4>
     <!-- <Sponsor3 :secSponsor3="'VIDEOS'" />-->
    </div>
    <div class="last-cont">

    <GalleryModal v-show="isModalVisible5" @close="closeModal5">
      <template v-slot:header>
        GALERÍA
      </template>

      <template v-slot:body> 
        <div class="gallery-item" v-for="(imadata,i) in modalData5" :key="imadata.id">
          <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata.image" alt="image" @click="showModal6(imadata,i)"/>
        </div> 
      </template>
    </GalleryModal>

    <GalleryImgModal v-show="isModalVisible6" @close="closeModal6">
      <template v-slot:body> 
        <div class="cont-img-gal">
          <div class="btn-content" @click="closeModal6">
          <a class="prev" id="pr" @click="plusSlides(modalData5,-1)" @click.stop="">❮</a>
          </div>
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalData6.image" alt="image"/>
          <div class="btn-content" @click="closeModal6">
          <a class="next" id="nx" @click="plusSlides(modalData5,1)" @click.stop="">❯</a>
          </div>
          </div>
        <div class="gallery-mosaic">
          <img v-for="(md,i) in modalData5" :key="md.id"
           :src="url+'/get-imagesgallery-image/'+md.image" alt="img" class="rest-of-pics" @click="showModal6(md,i)">
        </div>
      </template>
    </GalleryImgModal>

    
      <div class="l-cont" v-for="(gale,key) in gallerydata3" :key="key" @click="showModal5(gale.imagenes, gale.url)">
        <div class="l-div">
            <div :style="{ backgroundImage: 'url(' + url+'/get-imagesgallery-image/'+gale.image + ')'}" width="377" height="350" alt="e" class="l-m-content"/>
            <img
              src="../assets/red-rec.png"
              width="118"
              height="12"
              alt="rr"
              class="red-rec"
            />
        </div>
        <div class="titulo-vim">
          <h6 class="date">{{ dataSortDue(gale.dateimg) }}</h6>
          <a>
            <h4 class="titulo">{{ gale.title }}</h4>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VueCoreVideoPlayer from "vue-core-video-player";
import Sponsor3 from "../components/widgets/SponsorSecW3";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapActions } from "vuex";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue";

export default {
  components: {
    VueCoreVideoPlayer,
    Sponsor3,GalleryModal,GalleryImgModal
  },
  data() {
    return {
      isModalVisible5: false,
      modalData5: null,
      isModalVisible6: false,
      modalData6: null,
      modalData6A: [],
      indexModal:0,
    };
  },
  created() {
    this.getGallerys2();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    gallerydata3() {
      let galleryda = this.$store.getters["gallery/data4"];
      let elekipo = galleryda.sort(
        (a, b) => {return new Date(b.dateimg) - new Date(a.dateimg);}
      );
      return elekipo;
    },
  },
  methods: {
    ...mapActions("gallery", ["getGallerys2"]),

    showModal5(data, url) {
      console.log(url)
      if(url =='' || !url){
          this.isModalVisible5 = true;
          this.modalData5 = data;
      }else{
         const path ="/galeria/"+url
         window.location.href = path;  
      }
    },
    closeModal5() {
      this.isModalVisible5 = false;
      this.modalData5 = null;
    },

    showModal6(data, i) {
      this.isModalVisible6 = true;
      this.indexModal = i;
      this.modalData6 = data;
    },

    closeModal6() {
      this.isModalVisible6 = false;
      this.modalData6 = null;
    },

    plusSlides(data, n) {
      this.isModalVisible6 = true;
      let clairo = this.indexModal += n;
     /* console.log('cheems '+data.length)*/
      if(clairo < 0){
        document.getElementById('pr').style.pointerEvents = "none"
        document.getElementById('pr').style.opacity = ".5"
      }else if (clairo > data.length){
        document.getElementById('nx').style.pointerEvents = "none"
        document.getElementById('nx').style.opacity = ".5"
      }else{
      document.getElementById('pr').style.pointerEvents = "auto"
      document.getElementById('nx').style.pointerEvents = "auto"
        document.getElementById('pr').style.opacity = "1"
        document.getElementById('nx').style.opacity = "1"
      this.modalData6 = data[clairo];
      }
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("demo");
      let captionText = document.getElementById("caption");
      if (n > slides.length) {slideIndex = 1}
      if (n < 1) {slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex-1].style.display = "block";
      dots[slideIndex-1].className += " active";
      captionText.innerHTML = dots[slideIndex-1].alt;
    },


    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    getGallerys2: async function () {
      let result = await this.$store.dispatch("gallery/getGallerys2", {
        option: "galeria",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>

<style scoped>
.l-m-content {
    background-size: 140%!important;
    background-position-x: center;
    background-position-y: center;
    transition: .5s;
}
.l-cont:hover .l-m-content {
    background-size: 180%!important;
}
</style>