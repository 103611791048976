


<template>
  <div class="video-slides">
    <div class="header-video-cont">
      <div>
        <div class="video-cont">
          <!--<div class="titulo-video-cont">
            <div @click="showModal">
              <h6 class="data-video-sl">
                HACE {{ newsdateago(this.galeriadata.dateimg) }}
              </h6>
              <h1 class="titulo-vid-sl">
                {{ this.galeriadata.title }}
              </h1>
            </div>
          </div>-->
          <img class="video-header-sl pc" :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" />
          <img class="video-header-sl mo" :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" />
        </div>
      </div>
    </div>

    <GalleryModal v-show="isModalVisible" @close="closeModal">
      <template v-slot:header>
        {{ galeriadata.title }}
      </template>

      <template v-slot:body> 
        <div class="gallery-item" v-for="imadata in imagenesdata" :key="imadata.id">
          <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata.image" alt="image" @click="showModal3(imadata)"/>
        </div> 
      </template>
    </GalleryModal>

    <GalleryImgModal v-show="isModalVisible3" @close="closeModal3">
      <template v-slot:body> 
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalData2.image" alt="image"/>
      </template>
    </GalleryImgModal>

    <div class="slide-dest-cont">
      <GalleryModal v-show="isModalVisible2" @close="closeModal2">
        <template v-slot:header>
          GALERÍA
        </template>

        <template v-slot:body> 
          <div class="gallery-item" v-for="imadata1 in modalData" :key="imadata1">
            <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata1.image" alt="image" @click="showModal4(imadata1)"/>
          </div> 
        </template>
      </GalleryModal>

    <GalleryImgModal v-show="isModalVisible4" @close="closeModal4">
      <template v-slot:body> 
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalData3.image" alt="image"/>
      </template>
    </GalleryImgModal>

      <!--<div
        v-for="(gale, key) in galeriadata1.slice(0, 3)"
        :key="key"
        class="video-usl-cont" @click="showModal2(gale.imagenes) "
      >
        <img
          :src="url + '/get-gallery-image/' + gale.image"
          alt="video"
          class="vid-usl-cont"
        />
        <div class="t-usl-cont">
          <div class="text-tuslc">
            <h5>{{ gale.title }}</h5>
            <p>HACE {{ newsdateago(gale.dateimg) }}</p>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "vueperslides/dist/vueperslides.css";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue"; 
let urlpath = require('../global/url');

export default {
  components: { GalleryModal,GalleryImgModal},
  data() {
    return {
      isModalVisible: false,
      isModalVisible2: false,
      isModalVisible3: false,
      isModalVisible4: false,
      modalData: null,
      modalData2: null,
      modalData3: null,
      idgal:3,
      urlpath,
      backgr: [],
    };
  },
 async created() {
    this.getGalleryById();
    this.getGallerys1();
    this.findByGallery2();
    this.findByGallery();
      let payload2 = {
        id: 'Galeria',
        option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    galeriadata() {
      return this.$store.getters["gallery/data"];
    },
    galeriadata1() {
      let galleryda = this.$store.getters["gallery/data1"];
      return galleryda.sort((a, b) => new Date(b.dateimg).getTime() > new Date(a.dateimg).getTime()).reverse();
    },
    imagenesdata() {
      return this.$store.getters["gallery/data2"];
    },
    imagenesdata1() {
      return this.$store.getters["gallery/data3"];
    },
  },
  methods: {
    ...mapActions("gallery", ["findByGallery"]),
    ...mapActions("gallery", ["getGalleryById"]),
    ...mapActions("gallery", ["getGallerys1"]),
    ...mapActions("gallery", ["findByGallery2"]),
    ...mapActions("cabeceras", ["getCabecerasById"]), 

    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    showModal2(data) {
      this.isModalVisible2 = true;
      this.modalData = data;
    },
    closeModal2() {
      this.isModalVisible2 = false;
      this.modalData = null;
    },

    showModal3(data) {
      this.isModalVisible3 = true;
      this.modalData2 = data;
    },
    closeModal3() {
      this.isModalVisible3 = false;
      this.modalData2 = null;
    },

    showModal4(data) {
      this.isModalVisible4 = true;
      this.modalData3 = data;
    },
    closeModal4() {
      this.isModalVisible4 = false;
      this.modalData3 = null;
    },

    compareId: function(user) {
      var i;
      for (i = 0; i < user.roles.length; i++) {
        if (user.roles[i].name === "Admin") {
          return "Yes";
        }
      }

      return "-";
    },

    videoId: function (url) {
      let match = url.match(/v=([0-9a-z_-]{1,20})/i);
      return match ? match["1"] : false;
    },
    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    getGallerys1: async function () {
      let result = await this.$store.dispatch("gallery/getGallerys1", {
        option: "galeria",
      });
      this.info = result;
      return result;
    },

    getGalleryById: async function () {
      let idm = 2;
      let result = await this.$store.dispatch("gallery/getGalleryById", {
        option: "gallery",
        id: idm,
      });
      //this.info = result;
      return result;
    },

    findByGallery: async function () {
      let idm = 2;
      let result = await this.$store.dispatch("gallery/findByGallery", {
        option: "images_gallery/gallery",
        id: idm,
      });
      this.info = result;
      return result;
    },

    findByGallery2: async function (idgal) {
      let result = await this.$store.dispatch("gallery/findByGallery2", {
        option: "images_gallery/gallery",
        id: idgal,
      });
      this.info = result;
      return result;
    },


    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>

<style scoped>
.video-cont .slide-dest-cont{display: none;}
.video-header-sl.pc{
  display: block;
}
.video-header-sl.mo{
  display: none;
}
@media(max-width: 768px){
.video-header-sl.pc{
  display: none;
}
.video-header-sl.mo{
  display: block;
}
}
</style>